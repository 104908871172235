<template>
  <v-container fluid>
    <TheTitle :text="title" :icon="pageIcon">
      <v-row justify="center">
        <v-col md="8" sm="10" cols="12">
          <v-text-field
            placeholder="Procurar"
            dense
            solo
            background-color="#FFFFFF"
            hide-details
            v-model="search"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="10" cols="12">
          <v-btn block color="amber darken-4" dark @click="dialogSave()">
            <v-icon small class="mr-2">mdi-plus</v-icon>
            Adicionar
          </v-btn>
        </v-col>
      </v-row>
    </TheTitle>

    <v-card class="pa-5 full-height">
      <v-data-table 
        :headers="header" 
        :items="objects" 
        locale="pt-BR" 
        :search="search"
        :items-per-page="10"
      >
        <template v-slot:[`item.action`]="{ item }">
          <TheToolTip label="Etapas">
            <v-btn icon small tile>
                <v-icon @click="step(item.id)">mdi mdi-format-list-bulleted</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Editar">
            <v-btn icon small tile>
              <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Remover">
            <v-btn icon small tile>
              <v-icon @click="remove(item.id)">mdi-minus</v-icon>
            </v-btn>
          </TheToolTip>
        </template>
      </v-data-table>
    </v-card>

    <TheDialog v-model="dialog" :title="title" :icon="pageIcon" width="800px">
      <template>
        <v-form v-model="valid" ref="form1">
          <v-row>
            <v-col md="12" sm="12" cols="12">
              <b>Setor<sup>*</sup></b>
                <v-select
                  :items="sector"
                  placeholder="Selecione..."
                  item-text="name"
                  item-value="id"
                  v-model="object.idSector"
                  dense
                  outlined
                  class="mt-2"
                  clearable
                  :rules="[requiredRule]"
                ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" sm="12" cols="12">
              <b>Nome<sup>*</sup></b>
              <v-text-field
                :rules="[requiredRule, spaceRule]"
                v-model="object.name"
                dense
                outlined
                color="primary"
                class="mt-2"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:actions="" class="mr-2">
        <v-btn small depressed color="primary" @click="resolveForm()">
          <span v-if="!edit"><v-icon small class="mr-2">mdi-plus</v-icon>Adicionar</span>
          <span v-if="edit"><v-icon small class="mr-2">mdi mdi-update</v-icon>Atualizar </span>
        </v-btn>
        <v-spacer></v-spacer>
        <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
      </template>
    </TheDialog>
    
  </v-container>
</template>

<script>
import axios from 'axios'
import { baseApiUrl, showError } from '@/global'
import TheTitle from '@/components/TheTitle'
import TheDialog from '@/components/TheDialog'
import TheToolTip from '@/components/TheToolTip'

export default {
  name: 'Dados',
  components: {
    TheTitle,
    TheDialog,
    TheToolTip,
  },

  data: () => ({
    baseRoute: 'activity',
    title: 'Processos',
    pageIcon: 'mdi-run-fast',

    object: {},
    sector: [],
    objects: [],
    
    dialog: false,
    search: null,
    valid: false,
    edit: false,
    error: false,    
    
    header: [
      { text: 'Nome', value: 'name' },
      { text: 'Setor', value: 'sectorName' },
      { text: 'Ações', value: 'action', width: '10%' },
    ],

  }),

  methods: {

    loadData() {
      this.$store.dispatch('setLoadingInfo', true)
      const url = `${baseApiUrl}/${this.baseRoute}`
      axios
        .get(url)
        .then((res) => {
          this.$store.dispatch('setLoadingInfo', false)
          this.objects = res.data
        })
        .catch(showError)
    },

    loadSector() {
      const url = `${baseApiUrl}/sector`
      axios
        .get(url)
        .then((res) => {
          this.sector = res.data
        })
        .catch(showError)
    },

    dialogSave() {
      this.object = {}
      this.dialog = true
      this.edit = false
    },

    dialogUpdate(item) {
      this.object = item
      this.dialog = true
      this.edit = true
    },

    resolveForm() {
      this.$refs.form1.validate()
      if (!this.valid) {
        this.$store.dispatch('setErrorInfo', {
          open: true,
          text: 'Os campos não foram preenchidos corretamente, você pode verificar?',
          button: 'Ok!',
        })
        return
      }
      if (this.edit) {
        this.update()
      } else {
        this.save()
      }
    },

    save() {
      this.error = false
      const url = `${baseApiUrl}/${this.baseRoute}`
      const objects = this.object
      objects.list = this.list
      axios
        .post(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialogNew = false
          this.dialog = false
        })
    },

    update() {
      this.error = false
      const id = this.object.id
      const url = `${baseApiUrl}/${this.baseRoute}/${id}`
      const objects = this.object
      delete objects.id
      delete objects.sectorName

      delete objects.createdAt
      delete objects.updatedAt
      delete objects.deletedAt
      objects.list = this.list
      
      axios
        .put(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },

    remove(id) {
      if (confirm('Remove?')) {
        const url = `${baseApiUrl}/${this.baseRoute}/${id}`
        axios
          .delete(url)
          .then(() => this.loadData())
          .catch(showError)
      }
    },

    step(id){
      const route = {
          name: 'ProcessoEtapa',
          params: { id: id}
      }
      this.$router.push(route)
    },
    
  },

  mounted() {
    this.loadData()
    this.loadSector()
  },
}
</script>

<style>
.full-height {
  height: 80vh;
}
.row-activity {
  background: #ffffff;
}

.row-activity:hover {
  background: #eeeeee;
}
</style>
